/* You can add global styles to this file, and also import other style files */

$table-border-bottom: 1px solid var(--font-color);

:root {
  --font-color: rgb(0, 40, 74);
  --primary-black: rgba(0, 0, 0, .42);
  --light-placeholder-color: #0000001f;
  --white: #fff;
  --white-secondary: #eee;
  --highlighted-row-color: #ddd;
  --danger-color: #ee395b;
  --success-color: #5cb85c;
  --warn-color: #ffcc00;
  --gray-color: #c7c7c7;
  --sky-blue-color: #87c6eb;
  --one-month-color: blue;
  --almost-used-color: purple;
  --less-than-half-color: deeppink;
}

html,
h1,
span,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif !important;
}

.classic-button {
  color: var(--font-color);
  background-color: white;
  border-color: var(--font-color);
  border-width: 1px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  border-radius: 3px;
}

.red-button {
  color: red;
  border-color: red;
}

.list-spinner {
  height: 35px;
  width: 35px;
}

.gul-font-color {
  color: var(--font-color);
}

.thick-font {
  font-weight: 600 !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

* {
  box-sizing: border-box;
}

.small-header {
  font-family: "Lato", sans-serif !important;
  color: var(--font-color) !important;
  font-weight: 600 !important;
  font-size: 16px !important;
}

.display-flex {
  display: flex;
}

.small-padding-top {
  padding-top: 2px;
  padding-left: 2px;
}

.non-editable-input {
  background-color: var(--light-placeholder-color) !important;
  cursor: not-allowed;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--gray-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--font-color);
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
